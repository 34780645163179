import React, {useEffect,useState} from 'react';
import axios from 'axios';

import parseMessages from '../utils/gtfs-rt-utils';
import Table from '../components/table/table';
export default function TablePage() {
    const [vehPos, setVehPos] = useState([]);
    const getData= async ()=>{
	//console.log('getData() start...');
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    //TODO Make fields available via configuration!
            let url = 'https://api.entur.io/realtime/v1/gtfs-rt/vehicle-positions';
            const res = await axios.get(url,
					{
					    responseType: 'arraybuffer'
					});
            if(res.data){
                //console.log('getData() res available');
		const messages = parseMessages(res.data);
                //console.log('getData() messages.length: '+messages.length);
		setVehPos(messages);
	    }else{
                console.error('getData() res NOT available');
	    }
        } catch (err) {
            console.error('err.message: ' + err.message);
        }
	//console.log('getData() done.');
    };
    useEffect(()=>{
	getData();
	const intervalCall=setInterval(()=>{
	    getData();
	}, 5000);
	/*TODO adjust interval, make it available via config file*/
	return ()=>{
	    clearInterval(intervalCall);
	};
    },[]);
    return (
	<>
	    <Table messages={vehPos}/>
	</>
    );
}
