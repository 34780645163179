import React from 'react';
import PropTypes from 'prop-types';
import Body from './table-body';
import Head from './table-head';
export default function Table({messages}) {
    return (
	<>
	    <table>
		<thead>
		    <Head />
		</thead>
		<tbody>
		    <Body messages={messages} />
		</tbody>
	    </table>
	</>
    );
}
Table.propTypes = {
    messages: PropTypes.array
};
