import React from 'react';
import PropTypes from 'prop-types';

export default function TableBodyEntry({tsMsgCreationItcs,id,vehicleId,tripId,routeId,lat,lon}){
    return (
	<tr>
	    <td>{tsMsgCreationItcs}</td>
	    <td>{id}</td>
	    <td>{vehicleId}</td>
	    <td>{tripId}</td>
	    <td>{routeId}</td>
	    <td>{lat}</td>
	    <td>{lon}</td>
	</tr>
    );
}
