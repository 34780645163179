import React from 'react';
export default function TableHead(){
    return (
	<tr>
	    <th>tsMsgCreationItcs</th>
	    <th>id</th>
	    <th>vehicleId</th>
	    <th>tripId</th>
	    <th>routeId</th>
	    <th>lat</th>
	    <th>lon</th>
	</tr>
    );
};
