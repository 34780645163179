import React from 'react';
import PropTypes from 'prop-types';

import Entry from './table-body-entry';
export default function TableBody({messages}) {
    //console.log('TableBody: messages.length:'+messages.length);
    if(messages==undefined || messages==null){
	return (
	    <p>TableBody not available</p>
	);
    }else if(messages.length > 0){
	return messages.map((item, index) => {
	    return (
		<Entry
		    tsMsgCreationItcs={item.tsMsgCreationItcs}
		    id={item.id}
		    vehicleId={item.vehicleId}
		    tripId={item.tripId}
		    routeId={item.routeId}
		    lat={item.lat}
		    lon={item.lon}
		    key={index}
		/>
	    );
	});
    }else{
	return (
	    <p>TableBody loading...</p>
	);
    }
}
TableBody.propTypes = {
    messages: PropTypes.array
};
